@import 'reset';
@import 'breakpoint';

// Colour definitions
$colWhite: #fff;
$colBlack: #000;
$colPaleGrey: #eee;
$colLightGrey: #aaa;
$colGrey: #808080;
$colDarkGrey: #444;
$colTextGrey: #4D4D4D;

$colMGGreen: #5d9a8b;
$colMGGreenDk: #528b7d;
$colMGGreenPl: #deebe8;
$colMGBrown: #974b22;
$colMGBrownPl: #f2e8e2;
$colMGBlue: #7fa4c3;
$colMGBlueMid: #c3cbd4;
$colMGBluePl: #e5edf3;
$colBrightYellow: #f5b255;

// Breakpoints
$bp1600Down: max-width 1600px;
$bp1500Down: max-width 1500px;
$bp1400Down: max-width 1400px;
$bp1300Down: max-width 1300px;
$bp1200Down: max-width 1200px;
$bp1100Down: max-width 1100px;
$bp1000Down: max-width 1000px;
$notUnlimited: max-width 124rem;
$bp900Down: max-width 900px;
$bp850Down: max-width 850px;
$bp800Down: max-width 800px;
$bp750Down: max-width 750px;
$bpMenuSwap: max-width 1000px;
$bp700Down: max-width 700px;
$bp600Down: max-width 600px;
$bp550Down: max-width 600px;
$bp500Down: max-width 500px;
$bp400Down: max-width 400px;
$bp350Down: max-width 350px;
$bp300Down: max-width 300px;

$padFull: 12vw;
$padReduced: 6vw;
$padTablet: 3vw;

$hoFull: 8rem;
$hoReduced: 6rem;

$tfHeading: 'Philosopher',serif;
$tfBody: 'acumin-variable', sans-serif;

* { box-sizing: border-box; }

html { scroll-behavior: smooth; font-size: 15px; }

body { font-family: $tfBody; font-weight: normal; font-variation-settings: 'wght' 300, 'wdth' 100, 'slnt' 0; display: flex; flex-direction: column; min-height: 100vh; scroll-behavior: smooth; -webkit-font-smoothing: subpixel-antialiased; justify-content: space-between; color: $colTextGrey; font-size: 15px; }

h1 { font-family: $tfHeading; font-weight: bold; font-size: 2.9rem; line-height: 1.25;
  @include breakpoint($bp500Down) { font-size: 2.5rem; }
}
h2 { font-family: $tfHeading; font-weight: bold; font-size: 2rem; line-height: 1.25; }
h3 { font-size: 1.5rem; line-height: 1.1; text-transform: uppercase; font-variation-settings: 'wght' 550, 'wdth' 100, 'slnt' 0; margin-bottom: .75rem; }
h4 { font-size: 1.25rem; line-height: 1.25; }
h5 { font-size: 1.1rem; line-height: 1.25; }
p { font-size: 1.1rem; line-height: 1.35; padding-bottom: 1rem;
  strong { font-variation-settings: 'wght' 600, 'wdth' 100, 'slnt' 0;  }
  em { font-style: italic; }
  &.flyout { color: $colWhite; background: $colMGGreen; font-family: "acumin-variable",sans-serif; font-variation-settings: 'wght' 400, 'wdth' 100, 'slnt' 0; letter-spacing: .1em; text-transform: uppercase; padding: .33rem 1rem; display: inline-block; }
  &.fine { font-size: 1rem; }
}
em { font-style: italic; }
.intro { font-size: 1.3rem;
  p { font-size: 1.3rem; }
  li { font-size: 1.3rem; }
  ul { list-style: square; display: flex; flex-wrap: wrap;
    li::marker { font-size: .8rem; }
  }
}
a { color: $colBlack;
  &.button { border: 1px solid $colMGGreen; color: $colMGGreen; border-radius: .33rem; display: inline-block; padding: .33rem .99rem; text-transform: uppercase; text-decoration: none; font-family: "acumin-variable",sans-serif; font-variation-settings: 'wght' 400, 'wdth' 100, 'slnt' 0; letter-spacing: .08em; transition: background .4s, color .4s; font-size: .9rem;
    &.butBrown { border-color: $colMGBrown; color: $colMGBrown;
      &.solid { background: $colMGBrown; color: $colWhite; }
      &:hover { background: $colMGBrown; color: $colWhite;
        &.solid { background: $colWhite; color: $colMGBrown; }
      }
    }
    &.solid { background: $colMGGreen; color: $colWhite; border-color: $colWhite; }
    &:hover { background: $colMGGreen; color: $colWhite;
      &.solid { background: $colWhite; color: $colMGGreen; }
    }
  }
  &.playback { display: block; border: 1px solid $colMGBlue; border-radius: 50%; width: 3rem; height: 3rem; position: relative;  transition: background .4s;
    svg { fill: $colMGBlue; width: 1rem; height: 1.8rem; position: absolute; top: 50%; left: 50%; transform: translate(-40%,-50%); transition: fill .4s; }
    &:hover { background: $colMGBlue;
      svg { fill: $colWhite; }
    }
  }
}
blockquote {  }
strong { font-weight: normal; font-variation-settings: 'wght' 700; }

.tfIntroPara { font-family: "acumin-variable",sans-serif; font-variation-settings: 'wght' 300, 'wdth' 100, 'slnt' 0;  }
.tfSemiBold { font-family: "acumin-variable",sans-serif; font-variation-settings: 'wght' 400, 'wdth' 100, 'slnt' 0; letter-spacing: .06em; }

.alLeft { text-align: left; }
.alCenter { text-align: center; }
.alRight { text-align: right; }

:target:before { content:""; display:block; height: $hoFull; margin: -$hoFull 0 0; align-self: flex-start; justify-self: flex-start;
  @include breakpoint($bpMenuSwap) { height: $hoReduced; margin: -$hoReduced 0 0; }
}
.bgAlternatingTint:target:before { height: 11.5rem; margin: -11.5rem 0 0;
  @include breakpoint($bpMenuSwap) { height: 9.5rem; margin: -9.5rem 0 0; }
}
.anchor { display:block; position: relative; top: -10rem; visibility: hidden;
  &:target:before { display: none; }
}
header { z-index: 100; position: fixed; width: 100%; height: $hoFull; background: rgba($colWhite,.95);
  @include breakpoint($bpMenuSwap) { min-height: unset; height: $hoReduced; padding: 0; display: flex;
    input[type=checkbox]:checked ~ nav { transform: translateX(0); }
  }
  input[type=checkbox] { display: none; }
  label[for=menuToggle] { padding-top: 1.33rem; height: 100%; width: 2rem; display: none; align-items: center; justify-content: flex-start; position: absolute; right: $padReduced; transition: width 1s; cursor: pointer; z-index: 10;
    @include breakpoint($bpMenuSwap) { display: flex; }
  }
  .bg { position: absolute; background: $colPaleGrey; opacity: .83; width: 100%; height: 6.5rem; z-index: -1;
    @include breakpoint($bpMenuSwap) { height: 4rem; }
  }
  .mobLogo { display: none; padding: .5rem 1rem .5rem; z-index: 10;
    @include breakpoint($bp1300Down) { padding: 1.25rem 0 0 $padReduced; }
    @include breakpoint($bpMenuSwap) { padding: .6rem 0 .6rem $padReduced; }
    a { max-height: 100%; max-width: 100%; display: flex; align-items: flex-start; padding: 0; transition: transform .3s;
      &:hover { transform: scale(1.05); }
    }
    .gn { height: 80%;
      @include breakpoint($bp400Down) { height: 14.3vw; }
      img { display: block; height: 100%; width: auto; padding-right: .75rem;
        @include breakpoint($bp400Down) { padding-right: 3vw; }
      }
    }
    .txt { display: flex; flex-direction: column; justify-content: space-between; height: 3.75rem; width: 12rem;
      @include breakpoint($bp400Down) { height: 14vw; width: 45vw; }
      svg { fill: $colMGGreen; height: 100%; }
    }
    @include breakpoint($bpMenuSwap) { display: flex; align-items: center; }
  }
  .navStack { display: flex; flex-direction: column-reverse; height: 5rem; position: absolute; bottom: 0; right: -1rem;
    @include breakpoint($bpMenuSwap) { right: unset; top: 2rem; bottom: unset; flex-direction: column; height: unset; background: $colMGGreen; padding: 2rem 1rem 1rem; max-height: calc(100vh - 6rem); overflow: scroll; }
  }
  nav { display: flex; justify-content: space-between; align-items: center; margin: 0 auto; padding: 0; max-width: 100rem; height: 100%; position: relative;
    @include breakpoint($notUnlimited) { margin: 0 $padFull; }
    @include breakpoint($bp1300Down) { margin: 0 $padReduced; }
    @include breakpoint($bp1100Down) { margin: 0 $padTablet; }
    .logo { height: 4rem; width: 16.25rem; display: block; flex-shrink: 0;
      @include breakpoint($bpMenuSwap) { display: none; }
      a { max-height: 100%; max-width: 100%; display: flex; align-items: flex-start; padding: 0; transition: transform .3s;
        &:hover { transform: scale(1.05); }
      }
      .gn { height: 83%;
        img { display: block; height: 92%; width: auto; padding-right: 1rem; }
      }
      .txt { display: flex; flex-direction: column; justify-content: space-between; height: 3.75rem; width: 12rem;
        svg { fill: $colMGGreen; height: 100%; }
      }
    }
    .menuHandle { display: none; left: -2rem; position: absolute; top: 2rem; }
    @include breakpoint($bpMenuSwap) { margin: 0; position: absolute; right: 0; height: auto; padding: 0 1rem 2rem;width: 100%; transition: transform .75s; transform: translateX(100%); z-index: 5;
      .menuHandle { display: block; }
    }
    ul { display: flex; height: 100%; width: 100%; justify-content: space-between; align-items: stretch; list-style: none;
      @include breakpoint($bpMenuSwap) { flex-direction: column; align-items: flex-start; }
      svg { max-height: 1.25rem; max-width: 1.5rem; margin-right: .5rem; fill: $colMGGreen; transition: fill .4s;
        @include breakpoint($bpMenuSwap) { fill: $colWhite; }
      }
      &.rise { justify-content: flex-end;
        a { padding-bottom: 1rem; }
        img {  margin-right: .5rem; }
      }
      .leaf { position: absolute; left: 50%; transform: translateX(-50%); opacity: 0; z-index: 10;
        @include breakpoint($bpMenuSwap) { display: none; }
        svg { fill: $colMGGreen; width: 3rem; max-width: none; }
      }
    }
    li:last-of-type { justify-self: flex-end; }
    a { text-decoration: none; text-transform: uppercase; height: 100%; display: block; align-items: center; position: relative; padding: 0 1rem; text-shadow: 0 0 0.1rem rgba(0, 0, 0, .1); color: $colMGGreen; text-wrap: nowrap; transition: color .4s; padding-bottom: 1.75rem;
      @include breakpoint($bpMenuSwap) { color: $colWhite; padding-bottom: 0; }
      &:hover { color: $colMGBrown;
        @include breakpoint($bpMenuSwap) { color: $colWhite; text-decoration: underline; text-underline-offset: .2em; }
        svg { fill: $colMGBrown; }
      }
    }
    li { position: relative;
      @include breakpoint($bpMenuSwap) { padding-bottom: 1.75rem; }
      .arrow_box { opacity: 0; transition: opacity .4s; }
      ul { position: absolute; left: -1rem; min-width: 100%; display: flex; flex-direction: column; justify-content: flex-start; align-items: stretch; align-self: flex-start; height: auto; width: auto; max-height: 0; overflow: hidden; opacity: 0; transition: opacity .4s, max-height 0s .5s; text-align: left; text-decoration: underline; text-decoration-color: $colMGGreen;  background: $colMGGreen; letter-spacing: .06em;
        @include breakpoint($bpMenuSwap) { padding-left: .5rem; position: relative; top: unset; left: unset; background: none; transition: none; }
        a { padding: .5rem 1rem; text-align: left; width: 100%; text-wrap: nowrap; text-transform: none; color: $colWhite; text-decoration: underline; text-decoration-color: $colMGGreen; transition: text-decoration-color .4s; text-underline-offset: .2em; text-decoration-thickness: .08em; font-variation-settings: "slnt" 0, "wdth" 100, "wght" 300; white-space: nowrap;
          @include breakpoint($bpMenuSwap) { padding-left: 0; }
          &:hover { text-decoration-color: $colWhite; color: $colWhite; }
        }
        li { margin-right: 1rem; margin-left: 1rem; background: $colMGGreen;
          @include breakpoint($bpMenuSwap) { padding-bottom: 0; }
          &.first { margin-top: 1.5rem;
            @include breakpoint($bpMenuSwap) { margin-top: .5rem; }
          }
          &.last { margin-bottom: 1.5rem;
            @include breakpoint($bpMenuSwap) { margin-bottom: 0; }
          }
        }
      }
      &:last-of-type ul { left: unset; right: -1rem;
        @include breakpoint($bpMenuSwap) { right: unset; }
      }
      &:hover {
        .arrow_box { opacity: 1; }
        ul { max-height: 100vh; opacity: 1; transition: opacity .4s; overflow: visible;
          @include breakpoint($bpMenuSwap) { transition: none; }
          &:before { content: ''; display: block; height: 100%; position: absolute; background: $colMGGreen; left: -100vw; width: 200vw; display: none; }
          li:first-of-type a { text-decoration-color: $colWhite;
            @include breakpoint($bpMenuSwap) { text-decoration-color: transparent; }
          }
          &:hover { li:first-of-type a { text-decoration-color: transparent;
            &:hover { text-decoration-color: $colWhite; }
          } }
        }
      }
      &.active {
        a { color: $colMGBrown; fill: $colMGBrown;
          @include breakpoint($bpMenuSwap) { color: $colWhite; fill: $colWhite; }
        }
        .leaf { opacity: 1; }
        ul {
          .leaf { display: none; }
          a { color: $colWhite; }
        }
        svg { fill: $colMGBrown;
          @include breakpoint($bpMenuSwap) { fill: $colWhite; }
        }
      }
    }
    @include breakpoint($bpMenuSwap) { margin-top: 4rem;
      &>ul { padding-top: 1rem;
        &>li { margin-bottom: .5rem;
          &.first { display: none; }
        }
        ul { margin-top: 1rem; }
        .first a { padding: 0 1rem .5rem; }
        [src$=svg] { padding: .25rem 0; }
      }
    }
  }
}

.arrow_box { position: relative; background: #5d9a8b; }
.arrow_box:after { bottom: 100%; left: 50%; border: solid transparent; content: ""; height: 0; width: 0; position: absolute; pointer-events: none; border-color: rgba(93, 154, 139, 0); border-bottom-color: #5d9a8b; border-width: 10px; margin-left: -10px; }

.tintSection { position: relative; overflow: hidden;
  .title { display: flex; justify-content: flex-start; align-items: flex-end; }
  h1 { color: $colMGGreen; margin: 0 0 1rem 1.25rem; padding: 0; line-height: 1;
    span { font-weight: normal; font-variation-settings: 'wght' 400, 'wdth' 100, 'slnt' 0; border-left: 2px solid $colMGBlue; padding-left: 1rem; margin-left: 1rem; font-family: $tfBody; font-size: 1.75rem;
      @include breakpoint($bp600Down) { display: block; margin-left: 0; border-left: none; padding-left: 0; }
    }
  }
  h2 { color: $colMGGreen; margin: 0 0 1rem 1.25rem; padding: 0; line-height: 1; }
  .icon { background: $colMGGreen; height: 9rem; width: 5.5rem; display: flex; border-radius: 0 0 3rem 0; margin: 0; align-items: flex-end; justify-content: center; padding: 0 1.1rem 1.5rem .9rem;
    svg { fill: $colWhite; width: 1.5rem; height: 3rem; width: 3rem; margin: 0; }
  }
  &.blue {
    h1,
    h2 { color: $colMGBlue; }
    .icon { background: $colMGBlue; }
  }
}

.leaves { position: absolute; top: 0; right: 0; max-width: 40rem; min-width: 30rem; width: calc((100vw - 50rem) / 2); transform: translate(33%,-25%);
  @include breakpoint($bp800Down) { min-width: 20rem; }
  @include breakpoint($bp700Down) { display: none; }
  //@include breakpoint(max-width 104rem) { width: calc((100vw - 60rem) / 2); min-width: 20rem; }
  &.inPageTitle { min-width: 30rem; z-index: 1; }
  &.fullSize { width: calc((100vw - 20rem) / 2); transform: translate(33%, -20%); }
  &.flip { left: 0; transform: translate(-33%,-25%) scaleX(-1);
    @include breakpoint($bp1200Down) { display: none; }
  }
  &.vMiddle { top: 50%; transform: translateY(-50%);
    &.flip { transform: translate(-33%,-47%) scaleX(-1); }
  }
}
.gumnuts { position: absolute; top: 10%; right: 0; max-width: 20rem; min-width: 15rem; width: calc((100vw - 50rem) / 3); transform: translate(0,-50%) scaleY(-1); z-index: 1;
  &.low { top: 75%; transform: translate(0,-50%); }
  &.high { top: 0; transform: translate(0,-45%); }
  @include breakpoint(max-width 104rem) { width: calc((100vw - 60rem) / 2); min-width: 20rem; transform: scaleY(-100%); height: 20rem; min-width: 0; width: auto; }
  @include breakpoint(max-width 84rem) { height: 15rem; top: -1rem; }
  @include breakpoint($bp800Down) { display: none; }
}

#MannaCast .tintSection {
  .icon svg { height: 3.5rem; }
  @include breakpoint($bp600Down) {
    h1 span { display: block; margin-left: 0; border-left: none; padding-left: 0; }
  }
}

.latestPosts {
  .cardBlock { display: flex; justify-content: space-between; align-items: stretch; margin-top: 4rem; margin-bottom: 6rem; z-index: 5; position: relative;
    @include breakpoint($bp800Down) { flex-wrap: wrap; margin-top: 2rem; margin-bottom: 4rem; }
  }
  .card { background: $colMGGreenPl; width: calc(33.33% - 1.5rem); margin: 0; border-radius: .33rem; overflow: hidden; display: flex; flex-direction: column; align-items: flex-end;
    @include breakpoint($bp800Down) { width: calc(50% - 1rem);
      &:nth-of-type(3) { display: none; }
    }
    @include breakpoint($bp600Down) { width: 100%; margin-bottom: 6vw; }
    &>img { width: 100%; height: calc((100rem - 6rem) / 3 * .55); object-fit: cover; }
  }
  .text { padding: 1rem; width: 100%; flex-grow: 1;
    &>.title a { display: flex; align-items: center;
      .text { padding: 0; }
      p { padding: 0; font-size: .9rem; }
    }
    img { width: 3rem; height: 3rem; padding: .5rem; margin-right: .75rem; border-radius: 50%; border: 1px solid $colBlack; background: $colWhite; }
  }
  .blurb { margin-top: 1rem; display: -webkit-box; -webkit-line-clamp: 4; text-overflow: ellipsis; -webkit-box-orient: vertical; overflow: hidden; line-height: 1.35; }
  .fbLink { background: #006FB9; border-radius: 50%; width: 2rem; height: 2rem; display: flex; align-items: flex-end; margin: 0 1rem 1rem 0;
    svg { fill: $colWhite; height: 1.5rem; width: 2rem; margin-bottom: -1px; }
  }
  .blurb p { padding-bottom: .25rem; }
  a { text-decoration-color: transparent; transition: text-decoration-color .5s;
    &:hover { text-decoration-color: initial; }
  }
}

.jumpNav { padding: 2.5rem 0 .5rem; text-align: center; font-size: .7rem; line-height: 1; background: $colMGGreen;
  @include breakpoint($bp800Down) { padding: 3rem 0; }
  @include breakpoint($bp500Down) { background: $colMGGreenPl; }
  .nav { display: flex; gap: 1.5rem;
    @include breakpoint($bp500Down) { flex-direction: column; }
  }
  .sni { color: $colWhite; display: flex; flex-direction: column; justify-content: flex-start; align-items: center; text-transform: uppercase; letter-spacing: .08em; flex-basis: 0 0 20%; width: 20%; cursor: pointer; transition: color .4s; text-decoration: none;
    //@include breakpoint($bp800Down) { width: auto; }
    @include breakpoint($bp500Down) { flex-direction: row; width: 100%; margin-left: .4rem; align-items: center; color: $colMGGreen; }
    svg { fill: $colWhite;
      @include breakpoint($bp500Down) { fill: $colMGGreen; }
    }
    &:hover { //color: $colMGGreenPl;
      //svg { fill: $colMGGreenPl; }
      .GumLeaf { opacity: 1; }
    }
    &:nth-of-type(1) svg {
      @include breakpoint($bp500Down) { max-height: 2.2rem; }
    }
    &:nth-of-type(3) svg {
      @include breakpoint($bp500Down) { max-height: 2.2rem; }
    }
    &:nth-of-type(4) svg { max-width: 2.5rem;
      @include breakpoint($bp500Down) { max-width: none; max-height: 2.2rem; }
    }
    &:nth-of-type(5) svg { max-width: 2.5rem;
      @include breakpoint($bp500Down) { max-width: none; max-height: 2.2rem; }
    }
  }
  p { padding: 0;
    @include breakpoint($bp800Down) { font-size: 1rem; }
    //@include breakpoint($bp500Down) { font-size: 1.1rem; }
  }
  .link { text-decoration: none; }
  svg { max-width: 2rem; fill: $colMGGreen; max-height: 2.5rem; margin-bottom: .5rem; transition: fill .4s;
    @include breakpoint($bp500Down) { width: 5rem; max-width: none; height: 3rem; margin-bottom: 0; }
  }
  .GumLeaf { width: 5rem; min-width: 5rem; max-width: none; opacity: 0; transition: opacity .4s; max-height: 1.5rem; height: 1.5rem;
    @include breakpoint($bp800Down) { display: none; }
  }
}

.header { background: $colMGGreenPl; padding: 2rem 0; position: relative; margin-top: 3rem;
  @include breakpoint($bp1000Down) {
    &.twoTone { margin-top: 0; }
  }
  h1 { color: $colWhite; z-index: 10; position: relative;
    span { color: $colMGGreenPl; }
  }
  .strip { background: $colMGGreen; padding: 3rem 0; }
  .leaves { position: absolute; transform: scaleX(-1) translateY(-50%); height: 20rem; width: 20rem; top: 50%; left: calc((100% - 106rem) / 2); object-fit: contain;
    @include breakpoint($bp1200Down) { left: calc((100% - 100rem) / 2); }
    @include breakpoint($bp1000Down) { display: none; }
  }
  .textIntro { margin-top: 4rem; margin-bottom: 1rem;
    @include breakpoint($bp500Down) { margin-top: 3rem; margin-bottom: 0; }
    p { font-size: 1.15rem; }
  }
}
.theme { color: $colMGGreen; padding: 1rem 0; }

#aboutUs { padding: 0; overflow: hidden; position: relative;
  .c2 { width: 100%; }
  .c2>div  { width: 48%; flex: 0 0 auto; height: 35vw;
    &:nth-of-type(2) { position: absolute; top: 50%; left: 50%; transform: translateY(-50%); max-width: calc(50% - #{$padFull}); display: flex; flex-direction: column; justify-content: center;
      @include breakpoint($bp1300Down) { max-width: calc(50% - #{$padReduced})};
    }
  }
  h1 { font-family: $tfHeading; color: $colMGGreen; margin: 0 0 .33em 1.25rem; padding: 0; line-height: 1; }
  .title { display: flex; align-items: center; margin-bottom: .75rem;
    img { height: 3.5rem; }
  }
  #aboutFeat {  height: 35vw; position: relative; background: url(/assets/images/Watercolour.jpg); background-repeat: no-repeat; background-size: cover; background-position: right;
    .efa { fill: white; max-height: 17%; position: absolute; top: 50%; left: 63%; transform: translate(-50%,-160%); width: 45%; }
  }
  .blossom { position: absolute; bottom: -1.5rem; left: 63%; transform: translateX(-50%); background: #5d180a; mask-image: url(/assets/images/Gum-Blossom.svg); mask-repeat: no-repeat; width: 37%; }
}

#aboutUsAdapt { padding: 0; overflow: hidden; position: relative;
  .c2 { width: 100%;
    @include breakpoint($bp800Down) { align-items: normal; }
  }
  .c2>div { width: 48%;
    @include breakpoint($bp800Down) { width: 100%; }
    &:nth-of-type(2) { position: absolute; top: 50%; left: 50%; transform: translateY(-50%); width: calc(50% - #{$padFull}); max-width: 50rem; display: flex; flex-direction: column; justify-content: center;
      @include breakpoint($bp1300Down) { width: calc(50% - #{$padReduced}); }
      @include breakpoint($bp1000Down) { width: calc(50% - #{$padReduced}); }
      @include breakpoint($bp800Down) { position: relative; transform: none; width: 100%; left: 0; display: block; padding: 2rem 6vw; max-width: none; }
    }
  }
  h1 { font-family: $tfHeading; color: $colMGGreen; margin: 0 0 .33em 1.25rem; padding: 0; line-height: 1; }
  .title { display: flex; align-items: center; margin-bottom: .75rem;
    @include breakpoint($bp800Down) { display: none; }
    img { height: 3.5rem; }
  }
  #aboutFeat {  height: 32rem; position: relative; background: url(/assets/images/Watercolour.jpg); background-repeat: no-repeat; background-size: auto 100%; background-position: right;
    @include breakpoint($bp800Down) { overflow: hidden; left: 0; width: 100%; background-position-x: calc((100% - 17rem) / 2); }
    .efa { fill: white; position: absolute; top: 25%; right: 0; height: 3.75rem; width: 32.5rem;
      @include breakpoint($bp800Down) { left: 0; width: 100%; }
    }
  }
  .blossom { position: absolute; bottom: -5.5%; right: 0; background: #5d180a; mask-image: url(/assets/images/Gum-Blossom.svg); mask-repeat: no-repeat; width: 24.5rem; height: 20rem;
    @include breakpoint($bp800Down) { left: 47%; right: auto; transform: translateX(-30%); }
    @include breakpoint($bp500Down) { left: 46%; }
    @include breakpoint($bp400Down) { left: 45%; }
  }
}

.peopleCards { display: flex; flex-wrap: wrap; justify-content: space-between; z-index: 10;
  .mCard { flex: 0 0 calc(33.33% - 2rem); border-top-right-radius: 3rem; overflow: hidden; background: $colMGGreenPl; margin-bottom: 3rem; z-index: 10;
    @include breakpoint($bp900Down) { flex: 0 0 calc(33.33% - 1rem); }
    @include breakpoint($bp800Down) { flex: 0 0 calc(50% - 1.5rem); }
    @include breakpoint($bp600Down) { flex: 0 0 100%; max-width: 25rem; }
  }
  img { width: 100%; display: block; }
  h2 { background: $colMGGreen; color: $colWhite; padding: .5rem 2rem; font-size: 1.6rem; }
  p { padding: 2rem; }
  h2+p { margin-top: 0; }
}

.latestMMs { display: flex; align-items: center; z-index: 1; position: relative; padding: 2rem 0;
  @include breakpoint($bp1100Down) { align-items: flex-start; }
  @include breakpoint($bp800Down) { flex-direction: column; padding-bottom: 1rem; }
  .overview { border-right: 1px solid $colMGBlue; flex: 0 0 45%; padding: 2rem 2rem 2rem 0;
    @include breakpoint($bp1000Down) { flex: 0 0 40%; }
    @include breakpoint($bp800Down) { border-right: none; padding: 1rem 0; }
  }
  .thumb { max-width: 15rem;
    @include breakpoint($bp500Down) { max-width: none; display: flex; flex-direction: column; align-items: flex-start;
      a { display: none; }
    }
    img { max-width: 100%; margin-bottom: 1rem; }
  }
  .edition { border-left: 1px solid $colMGBlue; flex: 0 0 55%; padding: 2rem 0; margin-left: -1px;
    @include breakpoint($bp1300Down) {
      &:nth-of-type(odd) .preview { justify-content: flex-start; }
      &:nth-of-type(even) .preview { justify-content: flex-end; }
    }
    @include breakpoint($bp1000Down) { flex: 0 0 60%; }
    @include breakpoint($bp800Down) { border-left: 0; margin: auto; padding: 1rem 0; }
  }
  .details { margin-left: 1.5rem;
    @include breakpoint($bp500Down) { margin-left: 0; }
    h2 { margin: 2rem 0 0 0; font-weight: normal; color: $colMGBrown; display: none;
      @include breakpoint($bp500Down) { display: block; }
    }
    .download { display: none; margin-top: 2rem;
      @include breakpoint($bp500Down) { display: flex; }
    }
    li { border-bottom: 1px solid $colGrey; padding: 1rem 0 0; }
  }
  .preview { display: flex; justify-content: center; align-items: flex-start;
    @include breakpoint($bp1200Down) { margin-left: 2rem; }
    @include breakpoint($bp800Down) { margin-left: 0; }
    @include breakpoint($bp500Down) { flex-direction: column; }
  }
  .flyout { padding-left: 1.5rem; padding-right: 1.5rem; background: $colMGBrown;
    &.big { margin: 2rem 0 1.5rem -1.5rem; }
    &.small { display: none; }
    @include breakpoint($bp500Down) {
      &.big { display: none; }
      &.small { display: inline-block; }
    }

  }
  .download { display: flex; text-decoration: none; align-items: center; justify-content: flex-start; color: $colMGGreen;
    svg { max-height: 1.5rem; max-width: 1.5rem; fill: $colMGGreen; margin-right: .75rem; transition: fill .4s; }
    p { padding-bottom: 0; text-decoration: underline; text-underline-offset: .2em; text-decoration-color: transparent; transition: text-decoration-color .5s; text-decoration-thickness: .15em; font-variation-settings: "slnt" 0, "wdth" 100, "wght" 500; color: $colMGGreenDk; }
    &:hover p { text-decoration-color: $colMGGreen; }
  }
  ul { padding-left: 0; list-style-type: none;
    a { color: $colMGGreen; font-family: $tfHeading; font-size: 1.5rem; text-underline-offset: .2em; display: inline-block; max-width: 13em; line-height: 1.2; text-decoration-color: transparent; transition: text-decoration-color .5s;
      &:hover { text-decoration-color: $colMGGreen; }
      @include breakpoint($bp500Down) { max-width: 25rem; }
      //@include breakpoint($bp1100Down) { max-width: 11em; }
      //@include breakpoint($bp1000Down) {  }
    }
    p { padding: .1rem 0 .2rem; max-width: 13em; }
  }
  li::marker { font-size: .5rem; }
  .button { margin-top: 1.5rem; }
  &.inPage { display: block; display: flex; align-items: stretch; flex-wrap: wrap;
    .edition { flex: 0 0 50%; border-left: none; border-top: 1px solid $colMGGreen;
      &:nth-of-type(odd) { border-right: 1px solid $colMGGreen; }
      &:nth-of-type(1) { border-top: none; }
      &:nth-of-type(2) { border-top: none; }
      @include breakpoint($bp1600Down) {
        &:nth-of-type(odd) .preview { justify-content: flex-start; margin-right: 2rem; }
        &:nth-of-type(even) .preview { justify-content: flex-end; margin-left: 2rem; }
      }
      @include breakpoint($bp800Down) { border-top: none; margin-bottom: 4rem;
        &:nth-of-type(odd) { border-right: none;
          .preview { justify-content: center; margin-right: 0; }
        }
        &:nth-of-type(even) { border-left: none;
          .preview { justify-content: center; margin-left: 0; }
        }
      }
    }
    .empty:nth-child(even) { border-top: 1px solid $colMGGreen; width: 50%;
      @include breakpoint($bp800Down) { display: none; }
    }
  }
}

.subscribe { padding: 3rem 0; margin: auto;
  @include breakpoint($bp800Down) { padding-top: 1rem; }
  @include breakpoint($bp500Down) { border-top: 1px solid $colMGGreen; padding-top: 3rem; margin-top: 2rem; }
  h2 { text-align: center; margin: 0 0 1em; color: $colMGBrown; }
  p { padding: .5rem .66rem; color: $colGrey; }
  .spread { display: flex; margin-top: 1rem; align-items: center;
    @include breakpoint($bp500Down) { flex-direction: column; align-items: flex-start; }
  }
  input[type=submit] { float: none; margin: 2rem auto 0; display: block; }
}
form {
  .error { color: $colWhite; display: inline-block; background: $colMGBrown; padding: .5rem 1rem; margin: .5rem 0; font-weight: bold; font-size: 1.1rem; border-radius: .33rem; padding: 1rem; line-height: 1.4;
    &.over { text-align: center; display: block; margin: 2rem 0; }
  }
  .success { color: $colWhite; display: inline-block; background: $colMGGreen; font-weight: bold; font-size: 1.1rem; border-radius: .33rem; text-align: center; display: block; margin: 2rem 0; padding: 1rem; line-height: 1.4; }
}
footer form .success { border: 1px solid $colWhite; background: $colMGBlue; }
.latestPodcast { display: flex; z-index: 1; position: relative; padding: 2rem 0 4rem; align-items: center; margin-top: 2rem;
  @include breakpoint($bp800Down) { flex-wrap: wrap; }
  .overview { flex: 0 0 33.33%; padding: 2rem;
    @include breakpoint($bp1000Down) { flex: 0 0 35%; padding: 2rem 0 2rem 2rem; }
    @include breakpoint($bp800Down) { flex: 0 0 100%; padding: 2rem 0 0 }
  }
  .thumb {
    img { max-width: 15rem;
      @include breakpoint($bp800Down) { max-width: 13rem; }
      @include breakpoint($bp600Down) { max-width: 15rem; }
    }
    @include breakpoint($bp600Down) { display: flex; flex-direction: column; align-items: flex-start; }
  }
  .edition { flex: 0 0 60%; padding: 2rem 2rem 0 0; border-right: 1px solid $colMGBlue;
    @include breakpoint($bp1000Down) { flex: 0 0 65%; }
    @include breakpoint($bp800Down) { flex: 0 0 100%; border-right: none; padding: 0 0 2rem 0; border-bottom: 1px solid $colMGBlue; }
  }
  .episode { flex: 0 0 60%; padding: 2rem 2rem 0 0; border-right: 1px solid $colMGBlue; }
  .details { margin-left: 2rem;
    @include breakpoint($bp600Down) { margin: 2rem 0 0; }
    p:last-of-type { font-size: .9rem; font-style: italic; }
  }
  .preview { display: flex;
    @include breakpoint($bp600Down) { flex-wrap: wrap; }
  }
  .flyout{
    &.big { margin: 3rem 0 1.5rem -2rem; padding-left: 1rem; }
    &.small { display: none; }
    @include breakpoint($bp600Down) {
      &.big { display: none; }
      &.small { display: block; }
    }
  }
  ul { color: $colMGGreen; padding-left: 1em; list-style-type: square; }
  p { word-break: break-word; }
  li::marker { font-size: .5rem; }
  .button { margin-top: 1.5rem; }
  h3 { color: $colMGBlue; }
  .epID { font-variation-settings: 'wght' 600, 'wdth' 100, 'slnt' 0; color: $colMGGreen; text-transform: uppercase; letter-spacing: .06em; text-decoration: underline; text-decoration-thickness: .15em; text-underline-offset: .2em; padding-bottom: 1em; }
  .epTitle { text-transform: uppercase; letter-spacing: .03em; font-variation-settings: 'wght' 600, 'wdth' 100, 'slnt' 0; margin-bottom: 1.5rem; color: $colMGBlue; line-height: 1.2; text-decoration: underline; text-decoration-thickness: .1em; text-underline-offset: .1em; text-decoration-color: transparent; transition: text-decoration-color .5s; }
  a { text-decoration: none;
    &:hover .epTitle { text-decoration-color: $colMGBlue; }
  }
  &.inPage { display: flex; flex-direction: row; align-items: flex-start; flex-wrap: wrap; padding: 0; margin: 4rem 0;
    .inPage { margin: 0; }
    .podcast { flex: 0 0 50%; border-top: 1px solid $colMGBlue; padding: 2rem 2rem 1rem;
      &:nth-of-type(odd) { border-right: 1px solid $colMGBlue; padding-left: 0; }
      &:nth-of-type(even) { border-left: 1px solid $colMGBlue; margin-left: -1px; padding-right: 0; }
      &:nth-of-type(1) { border-top: none; padding-top: 0; }
      &:nth-of-type(2) { border-top: none; padding-top: 0; }
      @include breakpoint($bp800Down) { flex: 0 0 100%; padding: 0; border-top: none;
        &:nth-of-type(odd) { border-right: none; }
        &:nth-of-type(even) { border-left: none; }
      }
    }
    .episode { flex: 0 0 100%; border: none; padding: 0;
      @include breakpoint($bp800Down) { margin-bottom: 4rem; }
    }
    .details { margin: 0; align-items: center; font-size: 1rem; line-height: 1.35; }
    .title { display: flex; align-items: center; margin-bottom: 1rem;
      h3 { margin: 0; }
      a { flex: 0 0 3rem; margin-right: 1rem; }
    }
    .thumb { display: flex; align-items: flex-end; margin-bottom: 2rem;
      @include breakpoint($bp800Down) { flex-direction: column-reverse; align-items: flex-start; }
    }
    .flyout { margin: 0 0 3rem 0;
      @include breakpoint($bp800Down) { margin: 0; }
    }
  }
}

.circleFeature { padding: 4rem 0; z-index: 3; justify-content: center; gap: 4rem; position: relative;
  @include breakpoint($bp1100Down) { min-height: calc(45vw + 8rem); }
  &.flip .c2 { flex-direction: row-reverse;
    @include breakpoint($bp800Down) { flex-direction: column; gap: 2rem; }
  }
  &.vTop>div { position: sticky; top: 9rem; }
  .dotFieldContainer { display: flex; justify-content: flex-end; align-items: stretch; min-height: 35rem; min-width: 35rem; position: relative; display: flex; flex-direction: column; align-items: center; justify-content: center; width: auto; position: sticky; top: 10rem;
    @include breakpoint($bp1100Down) { height: 45vw; width: 45vw; min-width: unset; min-height: unset; }
    @include breakpoint($bp800Down) { position: relative; top: 0; }
    @include breakpoint($bp700Down) { min-width: 30rem; min-height: 30rem; }
    @include breakpoint($bp500Down) { min-width: unset; min-height: unset; width: 88vw; height: 88vw; }
  }
  .leaf { width: 15rem;
    @include breakpoint($bp1100Down) { width: 22vw; }
    @include breakpoint($bp700Down) { width: 12rem; }
    @include breakpoint($bp500Down) { width: 38vw; }
  }
  h1 { text-wrap: balance; max-width: 20rem; z-index: 10; text-align: center;
    @include breakpoint($bp1100Down) { font-size: 4.3vw; }
    @include breakpoint($bp700Down) { font-size: 2.5rem; }
  }
  svg { width: 100%; height: 100%; fill: $colBrightYellow; position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); }
  p { max-width: 40rem;
    &+.button { margin-top: 1rem; }
  }
  .textBox { width: auto;
    @include breakpoint($bp1100Down) { width: calc(50% - 6vw); }
    @include breakpoint($bp800Down) { width: 100%;
      p { max-width: none; }
    }
  }
  .c2 { justify-content: center; gap: 4rem; flex-wrap: nowrap;
    @include breakpoint($bp800Down) { flex-direction: column; gap: 2rem; align-items: center; }
  }
}

#Coming-up,
.UpcomingEvents {
  .cardBlock { margin-top: 4rem; margin-bottom: 2rem; display: flex; justify-content: center; align-items: flex-start; z-index: 5; position: relative; gap: 2rem;
    @include breakpoint($bp800Down) { justify-content: space-between; }
    @include breakpoint($bp500Down) { flex-wrap: wrap; gap: 6vw; }
    img { width: 100%; }
  }
  .card { flex: 0 0 33.33%;
    @include breakpoint($bp800Down) { flex: 0 0 calc((100% - 6vw) / 2); }
    @include breakpoint($bp500Down) { flex: 0 0 100%; max-width: 25rem; }
  }
  .date { margin-top: 1rem; font-variation-settings: 'wght' 550, 'wdth' 100, 'slnt' 0; color: $colMGGreen; text-transform: uppercase; letter-spacing: .06em; text-decoration: underline; text-decoration-thickness: .15em; text-underline-offset: .2em; padding-bottom: .5em; line-height: 1.5; margin-bottom: .5rem; }
  h3 { color: $colMGBlue; }
  .button { margin-bottom: 4rem; }
  a { text-decoration: none;
    h3 { text-decoration: underline; text-decoration-thickness: .2rem; text-underline-offset: .15em; text-decoration-color: transparent; transition: text-decoration-color .5s; line-height: 1.2; }
    &:hover h3 { text-decoration-color: $colMGBlue; }
  }
  .inPage {
    &.cardBlock { justify-content: flex-start; gap: 0; flex-wrap: wrap;
      @include breakpoint($bp700Down) { padding: 0 $padReduced; }
    .card { flex: 0 0 50%; border-top: 1px solid $colMGBlue; padding: 4rem 2rem 4rem;
      &:nth-of-type(1),
      &:nth-of-type(2) { border-top: none; padding-top: 0; }
      &:nth-of-type(odd) { border-right: 1px solid $colMGBlue; padding-right: 2rem; }
      &:nth-of-type(even) { border-left: 1px solid $colMGBlue; padding-left: 2rem; position: relative; margin-left: -1px; }
      @include breakpoint($bp700Down) { flex: 0 0 100%; max-width: 25rem; border-bottom: 1px solid $colMGBlue; margin-bottom: 4rem;
          &:nth-of-type(odd) { border-right: none; padding: 0 0 3rem 0; }
          &:nth-of-type(even) { border-left: none; padding: 0 0 3rem 0; }
        }
      }
    }
    .empty:nth-child(even) { border-top: 1px solid $colMGGreen; width: 50%;  }
    //.date { font-size: 1.1rem; line-height: 1.3; text-decoration: none; border-bottom: .2rem solid $colMGGreen; padding-bottom: .1rem; margin-bottom: 1rem; display: inline-block; }
  }
}

#Featured-publications,
.featPublications {
  .cardBlock { margin-top: 4rem; display: flex; justify-content: space-between; align-items: flex-start; margin: 4rem auto 1rem; z-index: 5; position: relative; gap: 6rem;
    @include breakpoint($bp1100Down) { margin-left: $padReduced; margin-right: $padReduced; gap: 4rem; }
    @include breakpoint($bp700Down) { gap: 3rem; }
    @include breakpoint($bp600Down) { flex-wrap: wrap; gap: 2rem;
      .card { width: calc(50% - 1.5rem);
        &:nth-of-type(3) { display: none; }
      }
    }
    @include breakpoint($bp400Down) { gap: 12vw;
      .card { width: 100%; }
    }
    img { width: 100%; }
  }
  h3 { color: $colMGBlue; margin: 1rem 0 .5rem; }
  .button { display: inline-block; margin: 2rem auto 4rem;
    @include breakpoint($bp1100Down) { margin: 1rem auto 2rem; }
  }
  a { text-decoration: none;
    h3 { text-decoration: underline; text-decoration-thickness: .2rem; text-underline-offset: .15em; text-decoration-color: transparent; transition: text-decoration-color .5s; line-height: 1.2; }
    &:hover h3 { text-decoration-color: $colMGBlue; }
  }
}
.Publications { padding: 4rem 0 0;
  h3 { color: $colMGBlue; margin: 1rem 0 .5rem; }
  h4 + p { margin-top: 1rem; }
  .card { width: 100%; display: flex; padding: 2rem 0;
    @include breakpoint($bp1000Down) { flex-direction: column; }
  }
  .image { margin-right: 2rem;
    img { width: 15rem; height: auto; display: block; }
  }
  .text { margin-top: 2rem;
    @include breakpoint($bp600Down) { margin-top: 1rem; }
    p:last-of-type { padding-bottom: .5rem; }
  }
  form input[type=image] { width: auto; padding: 0; }
  img { width: auto; height: auto; }
  .content { width: 100%; display: none; }
  .price { display: flex; color: $colMGBlue; margin: 1.5rem 0 1rem; align-items: flex-end;
    h3,p { padding: 0; margin: 0; }
    p { margin: 0 0 .2em .33em;
      &:last-of-type { padding-bottom: 0; margin-bottom: .1em; }
    }
  }
  .readMore { margin: .5rem 0 1rem; text-decoration-color: transparent; color: $colMGBlue; transition: text-decoration-color .5s; font-variation-settings: 'wght' 550, 'wdth' 100, 'slnt' 0; text-decoration-thickness: .1em; text-underline-offset: .15em;
    &:hover { text-decoration-color: $colMGBlue; }
  }
  .download { display: flex; text-decoration: none; align-items: center; justify-content: flex-start; color: $colMGBlue;
    svg { max-height: 1.5rem; max-width: 1.5rem; fill: $colMGBlue; margin-right: .75rem; transition: fill .4s; }
    p { padding-bottom: 0 !important; text-decoration: underline; text-underline-offset: .2em; text-decoration-color: transparent; transition: text-decoration-color .5s; font-variation-settings: "slnt" 0, "wdth" 100, "wght" 500; text-decoration-thickness: .1em; color: $colMGBlue; }
    &:hover p { text-decoration-color: $colMGBlue; }
    &+p { margin: 1rem 0 0; }
  }
  &.inPage { display: flex; flex-wrap: wrap;
    .card { flex: 0 0 50%; padding: 2rem; border-top: 1px solid $colMGBlue;
      &:nth-of-type(odd) { border-right: 1px solid $colMGBlue; padding-left: 0; }
      &:nth-of-type(even) { border-left: 1px solid $colMGBlue; padding-right: 0; margin-left: -1px; }
      &:nth-of-type(1),
      &:nth-of-type(2) { border-top: none; padding-top: 0; }
      @include breakpoint($bp600Down) { flex: 0 0 100%; padding: 0 0 6rem 0; border-top: none;
        &:nth-of-type(odd) { border-right: none; }
        &:nth-of-type(even) { border-left: none; }
      }
    }
  }
  &.c2 { padding-top: 0; flex-wrap: nowrap;
    &>div { width: auto; flex: 1 1 auto;
      &:first-of-type { border-right: 1px solid $colMGBlue; padding-right: 2rem; margin-right: 2rem;
        @include breakpoint($bp500Down) { font-size: 8vw; }
      }
    }
    .price { flex-direction: column; align-items: flex-start; margin-top: 1rem; position: sticky; top: 8rem;
      h3 { font-size: 1.75rem; }
      p { font-size: 1rem; margin-top: .4rem; }
    }
    .details { display: flex; white-space: nowrap; align-items: flex-start; margin-bottom: 2rem; }
  }
  &.fullPage { margin-top: 1rem;
    @include breakpoint($bp900Down) { padding-top: 1rem; margin-top: 1rem; flex-direction: column;
        &>div:first-of-type { padding-right: 0; margin-right: 0; border-right: none; }
    }
    h3 { font-size: 2.25rem; margin: 0; }
    h2 { font-size: 1.85rem; font-style: italic; font-weight: normal; }
    p.butBrown { margin-top: 0; font-size: 1.35rem; }
  }
}
.pubImage { max-width: 100%; }
.pubFeat .c2 {
  img { max-width: 100%; }
  @include breakpoint($bp800Down) { flex-direction: column;
    &>div { width: 100% !important;
      &:first-of-type { border-right: none; padding-right: 0; margin-right: 0; }
    }
    img { max-width: 25rem; }
  }
}
#PubTest .c3 {
  @include breakpoint($bp800Down) { flex-direction: column;
    &>div { width: 100%; border-bottom: 1px solid $colMGBlue; margin-bottom: 2rem; padding-bottom: 2rem;
      &.buff { display: none; }
    }
  }
}
.payButton { display: inline-flex; flex-direction: column; align-items: center;
  img { margin-top: .25rem; width: auto !important; }
}

.aboutHeading { display: flex; width: 100%; position: relative; margin-top: 4rem; margin-bottom: 4rem;
  .about { flex: 0 0 50%;
    img { width: 100%; height: 100%; }
  }
  .text { flex: 0 1 50%; display: flex; flex-direction: column; justify-content: center; background: $colMGGreen; color: $colWhite; padding: 0 4rem; }
  .leaves { position: absolute; top: 100%; left: 0; height: 23rem; width: 100%; transform: translate(22%,-70%);
    svg { width: 100%; height: 100%; transform: scaleX(-1); }
  }
  .gumnuts { transform: scale(-1,1) rotate(110deg) translate(-7%,75%); }
  h1,h2 { margin-bottom: 1rem; color: $colWhite; }
  p,li { font-size: 1.25rem; }
  ul { list-style: square; }
}

.masonCards { z-index: 10;
  .mCard { background: $colMGGreen; width: calc(50% - 1.1rem);z-index: 10; margin: 0 0 2rem; border-top-right-radius: 3rem; overflow: hidden;
    @include breakpoint($bp600Down) { width: 100%; max-width: 25rem; }
    img { width: 100%; }
    .text { padding: 3rem 4rem 4rem;
      @include breakpoint($bp700Down) { padding: 2rem; }
    }
    h2,p,ol,ul,li,a { color: $colWhite; }
    .articleList { column-count: unset; padding: 1rem 0 0;
      a { color: $colWhite;
        &:hover { text-decoration-color: $colWhite; }
      }
      i { background: $colWhite; }
    }
    h2 { margin-bottom: 1rem; line-height: 1.1; }
    ul { padding-bottom: 0; }
    li:last-of-type,
    p:last-of-type { padding-bottom: 0; }
    form { margin-top: 2rem; }
  }
  &.twoUp .mCard {
    &:nth-of-type(4n + 2),
    &:nth-of-type(4n + 3) { background: $colMGGreenPl;
      h2 { color: $colMGGreen; }
      p, ol, ul, li, a { color: $colDarkGrey; }
      input[type=submit].green { border: 1px solid $colMGGreen; color: $colMGGreen; }
    }
    &:nth-of-type(even) { margin: 0 0 2rem; }
    @include breakpoint($bp600Down) {
      &:nth-of-type(4n + 2),
      &:nth-of-type(4n + 4) { background: $colMGGreenPl;
        h2 { color: $colMGGreen; }
        p, ol, ul, li, a { color: $colDarkGrey; }
        input[type=submit].green { border: 1px solid $colMGGreen; color: $colMGGreen; }
      }
      &:nth-of-type(4n + 3) { background: $colMGGreen;
        h2 { color: $colWhite; }
        p, ol, ul, li, a { color: $colWhite; }
        input[type=submit].green { border: 1px solid $colMGGreen; color: $colMGGreen; }
      }
    }
  }
  &.threeUp .mCard { width: calc(33.33% - 1.4rem);
    @include breakpoint($bp800Down) { width: calc(50% - 1.4rem); }
    @include breakpoint($bp600Down) { width: 100%; }
    &:nth-of-type(3n) { margin-right: 0; }
    &:nth-of-type(even) { background: $colMGGreenPl;
      h2 { color: $colMGGreen; }
      p, ol, ul, li, a { color: $colDarkGrey; }
      input[type=submit].green { border: 1px solid $colMGGreen; color: $colMGGreen; }
    }
    @include breakpoint($bp1000Down) {
      .text { padding: 2rem; }
    }
  }
}
.bgBrownTint .masonCards .mCard {
  &:nth-of-type(4n + 2),
  &:nth-of-type(4n + 3) { background: $colWhite;
    h2,p { color: $colMGGreen; }
    i { background: $colMGGreen; }
    a { color: $colMGGreen;
      &:hover { text-decoration-color: $colMGGreen; }
    }
  }
}
#themeCards .masonCards .mCard { width: calc((100% / 3) - 1.4rem); margin: 0 0 2rem; border-radius: 0 3rem 0 0; overflow: hidden;
  @include breakpoint($bp800Down) { width: calc((100% / 2) - 1.1rem); }
  @include breakpoint($bp500Down) { width: 100%; max-width: 25rem; }
  h2 { text-align: center; background: $colMGGreen; font-family: $tfBody; text-transform: uppercase; font-size: 1.5rem; font-weight: normal; padding: .66em 1rem; font-variation-settings: 'wght' 550, 'wdth' 100, 'slnt' 0; margin-bottom: 0; }
  p { color: $colTextGrey; padding: .25rem 0 .5rem; }
  img { width: 100%; border-radius: 0; display: block; }
  .text { padding: 0; }
  ul { column-count: 1; padding: 1rem 2rem 2rem; width: 100%; }
  &:nth-of-type(even) { background: $colWhite;
    h2 { color: $colWhite; }
    i { background: $colMGGreen; }
    a { color: $colMGGreen;
      &:hover { text-decoration-color: $colMGGreen; }
    }
  }
  &:nth-of-type(odd) { background: $colWhite;
    h2 { color: $colWhite; }
    a { color: $colMGGreen;
      &:hover { text-decoration-color: $colMGGreen; }
    }
  }
  &:nth-of-type(3n) { margin-right: 0; }
  .articleList { padding: 0; }
}
.themeCards { display: flex; gap: 2rem; margin-top: 2rem;
  @include breakpoint($bp500Down) { flex-wrap: wrap; }
}
.moreArticles { color: $colMGBrown; margin: 6rem 0 -2rem 0; font-size: 2.5rem;
  @include breakpoint($bp1000Down) { margin: 4rem 0 -3rem 0; }
  &+.themeCards { margin-top: 4rem; }
}
.themeCard { width: calc((100% - 4rem) / 3); flex: 0 0 auto; background: $colMGGreenPl; color: $colTextGrey; position: relative; border-radius: 0 3rem 0 0; overflow: hidden; text-decoration-color: transparent;
  @include breakpoint($bp800Down) { width: calc((100% - 2rem) / 2);
    &:nth-of-type(3) { display: none; }
  }
  @include breakpoint($bp500Down) { width: 100%;
    &:nth-of-type(3) { display: block; }
  }
  img { display: block; }
  .articleTitleImage { margin: 0; }
  .text { padding: 2rem 2rem 1rem; }
  h2 { font-weight: normal; font-size: 1.75rem; color: $colMGGreen; line-height: 1; text-decoration: underline; text-decoration-color: transparent; transition: text-decoration-color .5s; }
  .author { margin-top: .25rem; text-decoration: none; }
  .preview { padding: 0; text-decoration: none; }
  .button { border: 1px solid $colMGGreen; color: $colMGGreen; border-radius: .33rem; display: inline-block; padding: .5rem .66rem; text-transform: uppercase; text-decoration: none; font-family: "acumin-variable",sans-serif; font-variation-settings: 'wght' 400, 'wdth' 100, 'slnt' 0; letter-spacing: .08em; transition: background .4s, color .4s; font-size: .9rem; margin: 2rem 0 2rem 0;
  }
  &:hover { cursor: pointer;
    h2 { text-decoration-color: $colMGGreen; }
    .button { background: $colMGGreen; color: $colWhite; }
  }
}

@include breakpoint($bp500Down) {
  .avListing .articleImage { max-width: 19rem; }
}

input { border: none; padding: .5rem; border-radius: .33rem; display: block; width: 100%; font-size: .9rem; }
input[type=submit] { border: 1px solid $colMGGreen; color: $colMGGreen; border-radius: .33rem; padding: .2055em .99rem; text-transform: uppercase; text-decoration: none; font-family: "acumin-variable",sans-serif; font-variation-settings: 'wght' 400, 'wdth' 100, 'slnt' 0; letter-spacing: .08em; transition: background .4s, color .4s; font-size: .9rem; display: block; background: transparent; width: auto;
  &:hover { background: $colMGGreen; color: $colWhite; cursor: pointer; }
  &.blue { border: 1px solid $colMGBlue; color: $colMGBlue;
    &:hover { background: $colMGBlue; color: $colWhite; cursor: pointer; }
  }
  &.green { border: 1px solid $colWhite; color: $colWhite;
    &:hover { background: $colWhite; color: $colMGGreen; cursor: pointer; }
  }
}

.hamburger { font: inherit; display: inline-block; overflow: visible; margin: 0; cursor: pointer; transition-timing-function: linear; transition-duration: .3s; transition-property: opacity, filter; color: inherit; border: 0; background-color: transparent }
.hamburger-box { position: relative; display: inline-block; width: 40px; height: 24px }
.hamburger-inner,
.hamburger-inner:after,
.hamburger-inner:before { position: absolute; width: 2rem; height: .25rem; transition-timing-function: ease; transition-duration: .3s; transition-property: transform; border-radius: 2px; background-color: $colMGGreen; }
.hamburger-inner:after,
.hamburger-inner:before { top: -10px; display: block; content: "" }
.hamburger-inner:after { bottom: -10px; top: unset; }
header input[type=checkbox]:checked~nav { transform: translateX(calc(100% - 20rem)); }
header input[type=checkbox]:checked~label .hamburger-inner:before { transform: translate3d(8px, 3px, 0) rotate(45deg) scaleX(.7); }
header input[type=checkbox]:checked~label .hamburger-inner:after { transform: translate3d(8px, -3px, 0) rotate(-45deg) scaleX(.7); }

main { flex-grow: 1; margin-top: 8rem;
  @include breakpoint($bpMenuSwap) { margin-top: 6rem; }
  ol,ul { font-weight: normal; font-size: 1.1rem; line-height: 1.2; padding-bottom: 1rem; padding-left: 1.5rem; }
  ul { list-style: disc; }
  ol { list-style: decimal; }
  li { line-height: 1.35; }
  img.inContent { max-width: 100% !important; max-height: 100% !important; display: block; padding: 0 !important; margin: 0 !important; }
  h1,h2 { color: $colMGGreen; }
  .bgBlueTint {
    h1,h2 { color: $colMGBlue; }
    .butBrown { color: $colMGBrown; }
    .butGreen { color: $colMGGreen; }
  }
  .bgBrownTint {
    h1,h2 { color: $colMGBrown; }
    .butBlue { color: $colMGBlue; }
    .butGreen { color: $colMGBrown; }
  }
  .bgBrown {
    h1,h2 { color: $colWhite; }
    .butGreen { color: $colMGGreen; }
    .butBlue { color: $colMGBlue; }
  }
  h1+p { margin-top: 1.5rem; }
  h2+p { margin-top: 1rem; }
}

.heading {
  .title { background: $colMGGreen; color: $colWhite; display: inline-block; padding: 2rem 4rem; border-bottom-right-radius: 3.5rem;
    @include breakpoint($notUnlimited) { padding: 2rem; }
    h1,h2,p { display: inline-block; color: $colWhite; line-height: 1; }
    br+h2 { margin-top: .1em; font-weight: normal; }
    br+br { display: none; }
    br+br+p { margin: 1.2rem 0 1.5rem 0; }
    h2+br+.mmEdition { margin-top: 1.5rem; }
    p { font-size: 1.25rem; margin: 1.5rem 0; padding: 0;
      &.mmEdition { font-size: 1rem; margin: 0; background: #974b22; margin-left: -4rem; padding: .5rem 1rem .5rem 4rem;
        @include breakpoint($notUnlimited) { margin-left: -2rem; padding-left: 2rem; }
      }
    }
    a { text-decoration-color: transparent; color: $colWhite; transition: text-decoration-color .5s;
      &:hover { text-decoration-color: $colWhite; }
    }
  }
  &.cnWide { margin-bottom: 4rem;
    &>div { padding: 0;
      @include breakpoint($notUnlimited) { margin-left: $padFull; margin-right: $padFull; }
      @include breakpoint($bp800Down) { margin-left: $padReduced; margin-right: $padReduced; }
    }
  }
}
article {
  //h2,h3,p,table,ol,ul { max-width: 75%; margin-right: 0; margin-left: auto; }
  blockquote.breakOut { background: $colMGBrownPl; position: relative;
    img { position: absolute; top: -7%; left: 0; width: 10rem !important; display: none; }
    p { font-family: $tfHeading; font-size: 1.5rem; max-width: 100%; line-height: 1.25; font-style: italic; color: $colMGBrown; margin: 0; padding: 3rem;
      @include breakpoint($bp300Down) { padding: 2rem; }
    }
  }
  .articleImage { margin: 1rem 0 2rem; }
  h3 { color: $colMGGreen; font-family: 'Philosopher', serif; text-transform: none; font-size: 1.7rem; }
  blockquote:not(.breakOut) { padding-left: 2rem; }
  h2 + blockquote.breakOut { margin-top: 3rem; }
  h3 + blockquote.breakOut { margin-top: 2rem; }
  blockquote.breakOut + .constText { margin-top: 3rem; }
  blockquote.breakOut + p { margin-top: 2rem; }
  blockquote.breakOut + h2 { margin-top: 3rem; }
  .constText + blockquote.breakOut { margin-top: 2rem; }
  p + h2 { margin-top: 1rem; }
  ul + h2 { margin-top: 1rem; }
  p + h3 { margin-top: .5rem; }
  ul + h3 { margin-top: .5rem; }
  img { width: 100% !important; height: auto !important; }
  table { width: 100% !important; }
  table + p { margin-top: 2rem; }
  p + table { margin-top: 1rem; }
  table + h2 { margin-top: 2rem; }
  table + h3 { margin-top: 2rem; }
  h2 + table { margin-top: 1rem; }
  h3 + table { margin-top: 1rem; }
  h2 + blockquote { margin-top: 1rem; }
  h3 + blockquote { margin-top: 1rem; }
}
.themeBar.cnWide { position: sticky; top: 8rem; z-index: 10;
  @include breakpoint($bp1000Down) { top: 6rem; }
  a { color: $colMGGreen; text-decoration-color: transparent; transition: text-decoration-color .5s;
    &:hover { text-decoration-color: $colMGGreen; }
  }
}
.articleImage { margin: 0; position: relative;
  .caption { position: absolute; right: 0; bottom: 0; background: rgba($colWhite,.9); color: $colMGGreen; padding: .75rem 2rem; font-variation-settings: 'wght' 500, 'wdth' 100, 'slnt' 0; display: inline-block; text-align: right; margin: 0 !important; }
  &+blockquote+.articleImage { margin-top: 2rem; }
}
.articleImages { margin: 0; position: relative; display: flex; gap: .5rem; flex-wrap: wrap;
  .caption { right: 0; bottom: 0; background: rgba($colWhite,.9); color: $colMGGreen; padding: 0 1rem 1rem; font-variation-settings: 'wght' 500, 'wdth' 100, 'slnt' 0; display: inline-block; text-align: right; }
  img { width: calc(50% - .25rem) !important; flex: 0 0 auto; }
}
.constText + .articleImages { margin-top: 2rem; }
.articleImages + .constText { margin-top: 2rem; }
.articleTitleImage { position: relative;
  .titleCaption { position: absolute; right: 0; bottom: 0; background: rgba($colWhite,.9); color: $colMGGreen; padding: .75rem 2rem; font-variation-settings: 'wght' 500, 'wdth' 100, 'slnt' 0; display: inline-block; text-align: right; }
  img { max-width: 100%; width: 100%; display: block; }
}
.articleList { column-count: 3; column-gap: 2rem; padding: 4rem 0;
  &.tailList li:first-of-type { display: none;
    @include breakpoint($bp800Down) { display: block; }
    @include breakpoint($bp500Down) { display: none; }
  }
  //@include breakpoint($bp800Down) { column-count: 2; }
  a { color: $colMGGreen; text-decoration-color: transparent; transition: text-decoration-color .5s; display: block;
    &:hover { text-decoration-color: $colMGGreen; }
  }
  li { border-bottom: 1px solid $colGrey; padding: 1rem 0 0; -webkit-column-break-inside: avoid; break-inside: avoid; max-width: 25rem; }
  &.latestMMs {
    ul { column-count: 3; gap: 4rem; width: 100%;
      @include breakpoint($bp1000Down) { gap: 3rem; }
      @include breakpoint($bp800Down) { column-count: 2; }
      @include breakpoint($bp500Down) { column-count: 1; }
    }
    a { max-width: none; }
    .fine { font-size: 1rem; }
  }
}
table {
  th,td { padding: .25rem; }
  p { max-width: 100%; }
}

.title {
  &.breakout { background: $colMGGreen; color: $colWhite; display: inline-block; padding: 1.5rem 2rem; border-bottom-right-radius: 2.5rem; }
  &.bgBrown { background: $colMGBrown; }
}

.box { border: 1px solid $colMGBrown; position: relative; padding-bottom: 1.75rem; margin: 4rem auto;
  .title { text-align: center; background: $colMGBrown; color: $colWhite; padding: .5rem 2rem; display: inline-block; position: absolute; left: 50%; transform: translate(-50%,-50%); white-space: nowrap;
  }
  h2 { text-align: center; color: $colMGBrown; margin: 2rem 0 0; font-size: 1.5rem; line-height: 1.2; font-weight: normal; text-underline-offset: .2em; }
  h3 { text-align: center; color: $colMGBrown; font-size: 1rem; }
  p { padding-bottom: .5rem; transition: color .5s; color: $colTextGrey; }
  a { display: block; padding: 1rem 1.75rem .5rem; transition: background .5s; margin-top: -1px; text-decoration: none; line-height: 1.1;
    h2 { padding-top: .25rem; margin: 0; text-align: left; color: $colMGGreen; text-decoration: underline; transition: text-decoration-color .5s; text-decoration-color: transparent; }
    &:first-of-type { padding-top: .5rem; margin-top: 1rem; }
    &.here { background: $colMGGreen; padding-bottom: calc(.5rem + 1px); margin-top: -1px;
      &+.line { display: none; }
      h2,a,p { color: $colWhite; }
    }
    &:hover h2 { text-decoration-color: $colMGGreen; }
  }
  .fine { padding-bottom: 0; }
  .line { height: 1px; width: 90%; background: $colLightGrey; margin: auto; }
  h2 + p { margin-top: .25rem; padding: 0; }
  .button { display: inline-block; padding: .33rem .99rem !important; margin: 1.5rem 0 0 50%; transform: translateX(-50%);
    &:hover { background: $colMGGreen; color: $colWhite; }
  }
}


.c2 { display: flex; justify-content: space-between; flex-wrap: wrap;
  @include breakpoint($bp500Down) { flex-direction: column; gap: 2rem; }
  &>div { width: calc((100% / 2) - 2rem);
    @include breakpoint($bp700Down) {
      width: 100%;
    }
  }
  &.s31 {
    @include breakpoint($bp500Down) { flex-direction: column; gap: 2rem; }
    &>div:first-of-type { width: calc(75% - 2rem);
      @include breakpoint($bp500Down) { width: 100%; }
    }
    &>div { width: calc(25% - 2rem);
      @include breakpoint($bp800Down) { width: 25%; }
      @include breakpoint($bp500Down) { width: 100%; }
    }
  }
  &.s21 {
    @include breakpoint($bp500Down) { flex-direction: column; gap: 2rem; }
    &>div:first-of-type { width: calc(66.66% - 2rem);
      @include breakpoint($bp500Down) { width: 100%; }
    }
    &>div { width: calc(33.33% - 2rem);
      @include breakpoint($bp800Down) { width: 33.33%; }
      @include breakpoint($bp500Down) { width: 100%; }
    }
  }
  &.s12 {
    @include breakpoint($bp500Down) { flex-direction: column; gap: 2rem; }
    &>div:first-of-type { width: calc(33.33% - 2rem);
      @include breakpoint($bp800Down) { width: 33.33%; }
      @include breakpoint($bp500Down) { width: 100%; }
    }
    &>div { width: calc(66.66% - 2rem);
      @include breakpoint($bp500Down) { width: 100%; }
    }
  }
  &.s13 {
    @include breakpoint($bp500Down) { flex-direction: column; gap: 2rem; }
    &>div:first-of-type { width: calc(25% - 2rem);
      @include breakpoint($bp800Down) { width: 25%; }
      @include breakpoint($bp500Down) { width: 100%; }
    }
    &>div { width: calc(75% - 2rem);
      @include breakpoint($bp500Down) { width: 100%; }
    }
  }
  .vimeoVideo { padding: 0 !important;
    iframe { padding: 0 !important; }
  }
}
.c3 { display: flex; justify-content: space-between;
  &>div { width: calc((100% / 3) - 4rem); flex: 0 0 auto;
    @include breakpoint($bp800Down) { width: calc((100% / 3) - 2rem); flex: 0 1 auto; }
    &.buff { width: auto; flex: 1 1 auto;
      &.bd { border-right: 1px solid $colMGBlue; }
    }
    @include breakpoint($bp700Down) {
      width: 100%;
    }
    .constText { margin: 0;
      p:last-of-type { padding-bottom: .5rem; }
    }
  }
  .vimeoVideo { padding: 0 !important;
    iframe { padding: 0 !important; }
  }
}
.c4 { display: flex; justify-content: space-between; flex-wrap: wrap;
  &>div { width: calc((100% / 4) - 2rem);
    @include breakpoint($bp700Down) {
      width: calc((100% / 2) - 2rem);
    }
    @include breakpoint($bp400Down) {
      width: 100%;
    }
  }
  .vimeoVideo { padding: 0 !important;
    iframe { padding: 0 !important; }
  }
}

.vertPad { padding-top: 4rem; padding-bottom: 4rem;
  @include breakpoint($bp600Down) { padding-top: 2rem; padding-bottom: 2rem; }
}
.vertPadTop { padding-top: 4rem;
  @include breakpoint($bp600Down) { padding-top: 2rem; }
}
.vertPadBottom { padding-bottom: 4rem;
  @include breakpoint($bp600Down) { padding-bottom: 2rem; }
}

.cnNarrow { margin: auto; position: relative; z-index: 5;
  &>.constText,
  .constButton,
  .inContent,
  .vimeoVideo,
  .caption,
  &>div,
  iframe,
  &>h1,
  &>h2,
  &>h3 { max-width: 45rem; margin-left: auto; margin-right: auto;
    @include breakpoint($bp800Down) { margin-left: $padReduced; margin-right: $padReduced; max-width: none; }
  }
  .constText {
    h1,
    h2,
    h3 { padding: 0; }
  }
  .vimeoVideo {
    @include breakpoint($bp600Down) { margin-left: -4rem; width: calc(100% + 8rem); }
  }
}
.cnMedium { margin: auto; position: relative; z-index: 5;
  &>.constText,
  .constButton,
  .inContent,
  .vimeoVideo,
  .caption,
  &>div,
  iframe,
  &>h1,
  &>h2,
  &>h3 { max-width: 65rem; margin-left: auto; margin-right: auto;
    @include breakpoint($bp1100Down) { margin-left: $padReduced; margin-right: $padReduced; }
  }
  .constText {
    h1,
    h2,
    h3 { padding: 0; }
  }
  .vimeoVideo {
    @include breakpoint($bp600Down) { margin-left: -4rem; width: calc(100% + 8rem); }
  }
  .cnNone .constText { padding: 2rem; background: $colMGGreenPl; }
}
.cnWide { margin: auto; position: relative; z-index: 5;
  &>.constText,
  .constButton,
  .inContent,
  .vimeoVideo,
  .caption,
  &>div,
  iframe,
  &>h1,
  &>h2,
  &>h3 { max-width: 100rem; margin-left: auto; margin-right: auto;
    @include breakpoint($notUnlimited) { margin-left: $padFull; margin-right: $padFull; }
    @include breakpoint($bp1300Down) { margin-left: $padReduced; margin-right: $padReduced; }
    //@include breakpoint($bp1100Down) { margin-left: $padTablet; margin-right: $padTablet; }
  }
  .constText {
    h1,
    h2,
    h3 { padding: 0; }
  }
  .vimeoVideo {
    @include breakpoint($bp600Down) { margin-left: -4rem; width: calc(100% + 8rem); }
  }
}

.vTop { align-items: flex-start; }
.vCenter { align-items: center; }
.vBottom { align-items: flex-end; }
.vStretch { align-items: stretch; }
.dFlex { display: flex; }

.s1 { height: 1rem; }
.s2 { height: 2rem; }
.s3 { height: 3rem; }
.s4 { height: 4rem; }
.s5 { height: 5rem; }
.s6 { height: 6rem; }
.s7 { height: 7rem; }
.s8 { height: 8rem; }
.s9 { height: 9rem; }
.s10 { height: 10rem; }
@include breakpoint($bp800Down) {
  .s2 { height: 1rem; }
  .s3 { height: 1.5rem; }
  .s4 { height: 2rem; }
  .s5 { height: 2.5rem; }
  .s6 { height: 3rem; }
  .s7 { height: 3.5rem; }
  .s8 { height: 4rem; }
  .s9 { height: 4.5rem; }
  .s10 { height: 5rem; }
}

.bgGreenTint { background: $colMGGreenPl;
  hr { border: none; color: $colMGGreen; background: $colMGGreen; height: 1px; }
}
.bgBrownTint { background: $colMGBrownPl;
  h2 { color: $colMGBrown; }
  .button { color: $colMGBrown; border-color: $colMGBrown;
    &:hover { color: $colWhite; background: $colMGBrown; }
  }
}
.bgBlueTint { background: $colMGBluePl; }
.bgGreen { background: $colMGGreen; color: $colWhite;
  h2,p,input[type=submit] { color: $colWhite; }
  .button { border-color: $colWhite;
    &:hover { color: $colMGGreen; background: $colWhite; }
  }
}
.bgAlternatingTint {
  h2 { margin: 0; }
  &:nth-of-type(even) > div { background: $colMGGreenPl; }
  .articleList { padding: 2rem 0 6rem; }
}
.butBrown { color: $colMGBrown; }
.butBlue { color: $colMGBlue; }
.butGreen { color: $colMGGreen; }

#topicListReturn { position: sticky; top: 8rem; padding: 1rem; background: $colMGGreen; z-index: 10; text-align: center;
  @include breakpoint($bpMenuSwap) { top: 6rem; }
}

.preFooter { margin-top: 4rem; padding-top: 3rem;
  .c2 { align-items: flex-start; }
}
footer { background-color: $colMGGreen; color: $colWhite; padding: 4rem 0 0; width: 100%; position: relative; overflow: hidden;
  &>div>div { display: flex; gap: 3rem; justify-content: space-between;
    @include breakpoint($bp700Down) { flex-wrap: wrap; justify-content: center; gap: 3rem; }
    @include breakpoint($bp600Down) { justify-content: space-between; gap: 3rem; }
    @include breakpoint($bp500Down) { justify-content: space-between; gap: 0 3rem;
    }
    &>div { width: 16%;
      @include breakpoint($bp800Down) { width: 20%; }
      @include breakpoint($bp700Down) { width: 40%; }
      @include breakpoint($bp500Down) { width: 100%; }
      &:nth-of-type(1) { display: flex; flex-direction: column; justify-content: space-between; }
      &:nth-of-type(4) { width: 26%;
        @include breakpoint($bp700Down) { width: 40%; }
        @include breakpoint($bp500Down) { width: 100%; }
      }
      @include breakpoint($bp500Down) {
        &:nth-of-type(3) { margin-top: 3rem; }
        &:nth-of-type(4) { margin-top: 3rem; }
      }
    }
  }
  .fp { font-size: .8rem; }
  a { font-variation-settings: 'wght' 500, 'wdth' 100, 'slnt' 0; margin-bottom: .75rem; padding-bottom: 1rem; text-decoration-thickness: .1em; text-underline-offset: .2em; text-decoration-color: transparent; display: block; color: $colWhite; fill: $colWhite; align-items: center; transition: text-decoration-color .5s; text-transform: uppercase; letter-spacing: 0.06em; font-size: 1.1rem; white-space: nowrap;
    &:hover { text-decoration-color: $colWhite; }
    svg { height: 1.5rem; width: .75rem; margin-right: .75rem; flex: 0 0 auto; }
    &+p { margin-top: -1rem; }
  }
  .fine { margin-top: -.75rem;
    a { font-size: 1rem; font-variation-settings: 'wght' 400, 'wdth' 100, 'slnt' 0; padding-bottom: 0; line-height: 1.2; text-transform: none; text-decoration-thickness: .07em; white-space: unset; }
  }
  form .fine { margin-top: 0; }
  input+input { margin-top: 1rem; }
  input+p { padding: .75rem 0 1rem 1rem; display: block; }
  .button { border-color: $colWhite; color: $colWhite; font-size: .9rem; margin-bottom: 3rem;
    &:hover { color: $colMGGreen; background: $colWhite; }
  }
  .logo {
    @include breakpoint($bp700Down) {
      &.one { display: none; }
    }
    &.three { display: none; margin-top: 2rem;
      @include breakpoint($bp700Down) { display: block; }
      @include breakpoint($bp500Down) { display: none; }
    }
    &.four { display: none;
      @include breakpoint($bp500Down) { display: block; }
    }
    a { align-items: flex-start; }
  }
  .gn svg { width: 3rem; height: 2rem; margin-right: 0; }
  .txt svg { width: 7rem; height: 2.5rem; }
  .watermark { position: absolute; left: -4rem; top: 50%; transform: translate(-20%,-50%); height: 125%; width: 25rem;
    svg { width: 100%; height: 100%; }
  }
  .fbLink { text-transform: none; white-space: nowrap; font-variation-settings: 'wght' 550, 'wdth' 100, 'slnt' 0; letter-spacing: 0; font-size: 1.1rem; padding: 0;
    img { height: 1.5rem; vertical-align: middle; margin: -.33rem .5rem 0 0; }
  }
  strong { font-variation-settings: 'wght' 550, 'wdth' 100, 'slnt' 0; }
}
#SubscribeInFoot { margin: 0; padding: 0; }

.buttonCloud {
  a { margin: .25rem; }
}

.videoShell { width: 100%; margin: auto; max-width: 50rem; }
.videoEmbed { position: relative; height: 0; overflow: hidden; padding-bottom: 56.25%; height: auto;
  iframe { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
}

.jumpArrow { display: block; width: 100%; text-align: center; padding: 2rem 0;
  img { height: 4rem; }
  &.inHeader { display: block; padding: 0; color: $colMGGreen; font-variation-settings: 'wght' 550, 'wdth' 100, 'slnt' 0; text-underline-offset: .2rem; text-decoration-thickness: .1em; font-size: 1.2rem; text-decoration-color: transparent; transition: text-decoration-color .5s; margin-left: -1rem;
    &:hover { text-decoration-color: $colMGGreen; }
    img { height: 3rem; margin-right: 1rem; vertical-align: middle; }
  }
}